// project import
import applications from './applications';

// types
import { NavItemType } from 'types/menu';
import DashboardDetail from './dashboardDetail';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [DashboardDetail, applications]
};

export default menuItems;
