// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";

// type
import { NavItemType } from "types/menu";

// icons
const icons = {
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  FileTextOutlined,
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications: NavItemType = {
  id: "group-applications",
  title: <FormattedMessage id="applications" />,
  icon: icons.AppstoreAddOutlined,
  type: "group",
  children: [
    {
      id: "invoice",
      title: <FormattedMessage id="invoice" />,
      url: "/apps/invoice/dashboard",
      type: "collapse",
      icon: icons.FileTextOutlined,
      breadcrumbs: true,
      children: [
        {
          id: "create",
          title: <FormattedMessage id="create" />,
          type: "item",
          url: "/apps/invoice/create",
        },
        {
          id: "details",
          title: <FormattedMessage id="details" />,
          type: "item",
          url: "/apps/invoice/details/1",
        },
        {
          id: "list",
          title: <FormattedMessage id="list" />,
          type: "item",
          url: "/apps/invoice/list",
        },
        {
          id: "edit",
          title: <FormattedMessage id="edit" />,
          type: "item",
          url: "/apps/invoice/edit/1",
        },
      ],
    },
    {
      id: "profile",
      title: <FormattedMessage id="profile" />,
      type: "collapse",
      icon: icons.UserOutlined,
      children: [
        {
          id: "user-profile",
          title: <FormattedMessage id="user-profile" />,
          type: "item",
          url: "/apps/profiles/user/personal",
          breadcrumbs: false,
        },
        {
          id: "account-profile",
          title: <FormattedMessage id="account-profile" />,
          type: "item",
          url: "/apps/profiles/account/basic",
        },
      ],
    },
  ],
};

export default applications;
