// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DollarOutlined, LoginOutlined, PhoneOutlined, RocketOutlined, DashboardOutlined, GoldOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { DollarOutlined, LoginOutlined, PhoneOutlined, RocketOutlined, DashboardOutlined, GoldOutlined };

// ==============================|| MENU ITEMS - PAGES ||============================== //

const DashboardDetail: NavItemType = {
  id: 'dashboard',
  title: <FormattedMessage id="Dashboard" />,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'collapse',
      icon: icons.DashboardOutlined,
      children: [
        {
          title: <FormattedMessage id="default" />,
          type: 'item',
          url: '/dashboard/default',
          target: false
        }
      ]
    }
    // {
    //   id: 'components',
    //   title: <FormattedMessage id="Components" />,
    //   type: 'item',
    //   url: '/components-overview/buttons',
    //   icon: icons.GoldOutlined,
    //   target: true
    // }
  ]
};

export default DashboardDetail;
